import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdBarChart,
  MdPerson,
  MdHome,
  MdLock,
  MdOutlineShoppingCart,
} from "react-icons/md";

// Admin Imports
import MainDashboard from "views/admin/default";
import NFTMarketplace from "views/admin/marketplace";
import Profile from "views/admin/profile";
import DataTables from "views/admin/dataTables";
import RTL from "views/admin/rtl";
import PushNotifications from "views/admin/PushNotfication/PushNotfication";


// Auth Imports
import SignInCentered from "views/auth/signIn";
import {AiOutlineNotification} from "react-icons/ai";
import Dashboard from "views/admin/Dashboard/Dashboard";

const routes = [
  {
    name: "לוח בקרה",
    layout: "/admin",
    path: "/dashboard",
    icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
    component: Dashboard,
    showSideBar: true,

  },
  {
    name: "סטטיסטיקות",
    layout: "/admin",
    path: "/stats",
    icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
    component: RTL,
    showSideBar: true,

  },
  {
    name: "הודעות Push",
    layout: "/admin",
    path: "/push",
    icon: <Icon as={AiOutlineNotification} width='20px' height='20px' color='inherit' />,
    component: PushNotifications,
    showSideBar: true,

  },
  {
    name: "פרופיל",
    layout: "/admin",
    path: "/profile",
    icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
    component: Profile,
    showSideBar: true,
  },
  {
    name: "Sign In",
    layout: "/auth",
    path: "/login",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: SignInCentered,
    showSideBar: false,
  },

];

export default routes;
